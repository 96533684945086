import React, { useEffect, useState } from "react";
import SearchDropDownComp from "../components/SearchDropDownComp";
import { InputFieldComp } from "../components/InputFieldComp";
import { AddedItemsComp } from "../components/AddedItemsComp";
import { toast } from "react-toastify";
import { lsVariables } from "../local";
import { _getLocalStorageData } from "../helper";

export const SecondStep = ({ step, goBack, metaData, goNext }) => {
  const [states, setState] = useState({
    metaData: undefined,
    loading: true,
    socialMedia: undefined,
    expertise: undefined,
    expDescription: "",
    language: undefined,
    socialMediaLink: "",
    socialMediaList: [],
    expertiseList: [],
    languageList: [],
    loadData: true,
  });

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    const data = _getLocalStorageData(lsVariables.stepTwo);
    if (data)
      setState({
        ...states,
        socialMediaList: data?.socialMedia,
        expertiseList: data?.expertise,
        languageList: data?.languages,
      });
  }

  function addLanguage() {
    if (states.language === undefined) {
      toast.error("Please select language from dropdown");
      return;
    }

    const filterArray = states.languageList.filter(
      (item) => item?.id === states.language.id
    );

    if (filterArray.length > 0) {
      toast.error(`${states.language.label} already added`);
      return;
    }

    const list = states.languageList;
    list.push(states.language);
    setState({ ...states, languageList: list });
  }

  function addExpertise() {
    if (states.expertise === undefined) {
      toast.error("Please select experty from dropdown");
      return;
    }

    if (states.expDescription === "") {
      toast.error("Please provide little description of your experty");
      return;
    }

    const filterArray = states.expertiseList.filter(
      (item) => item?.id === states.expertise.id
    );

    if (filterArray.length > 0) {
      toast.error(`${states.expertise.label} already added`);
      return;
    }

    const list = states.expertiseList;
    states.expertise.desc = states.expDescription;
    list.push(states.expertise);
    setState({ ...states, expertiseList: list });
  }

  function addSocialMedia() {
    if (states.socialMedia === undefined) {
      toast.error("Please select platform from dropdown");
      return;
    }

    if (states.socialMediaLink === "") {
      toast.error("Please provide social media link");
      return;
    }

    const filterArray = states.socialMediaList.filter(
      (item) => item?.id === states.socialMedia.id
    );

    if (filterArray.length > 0) {
      toast.error(`${states.socialMedia.label} already added`);
      return;
    }

    const list = states.socialMediaList;
    states.socialMedia.link = states.socialMediaLink;
    list.push(states.socialMedia);
    setState({ ...states, socialMediaList: list });
  }

  function checkFrom() {
    if (states.socialMediaList.length === 0) {
      toast.error(`Please provide social media profile links`);
      return;
    }
    if (states.expertiseList.length === 0) {
      toast.error(`Please provide your expertise`);
      return;
    }
    if (states.languageList.length === 0) {
      toast.error(`Please provide progamming languages`);
      return;
    }
    const data = {
      socialMedia: states.socialMediaList,
      expertise: states.expertiseList,
      languages: states.languageList,
    };
    localStorage.setItem(lsVariables.stepTwo, JSON.stringify(data));
    goNext(data);
  }

  return (
    <div className="mt-5 h-[90%]">
      <div className="px-[2%] py-[1%] h-[80%] overflow-auto">
        <div>
          <div className="mt-5">
            <p className="text-sm font-bold mb-3">
              Add Social Media/Profile links
            </p>
            <div className="lg:flex">
              <div className="lg:px-1 lg:w-[15%] lg:items-center lg:flex">
                <SearchDropDownComp
                  extraStyle={"z-10"}
                  options={metaData.socialMediaList}
                  onSelected={(item) =>
                    setState({ ...states, socialMedia: item })
                  }
                />
              </div>
              <div className="lg:w-[75%] lg:px-2 mt-2 lg:mt-0">
                <InputFieldComp
                  title={""}
                  maxLength={200}
                  defaultValue={states.socialMediaLink}
                  placeHolder={"link"}
                  onTextChange={(text) =>
                    setState({ ...states, socialMediaLink: text })
                  }
                />
              </div>
              <div className="lg:flex lg:w-[5%] lg:justify-center items-center lg:ml-5 mt-2 lg:mt-0">
                <div
                  onClick={() => addSocialMedia()}
                  className="bg-green-600 h-[40px] flex items-center text-white text-sm px-7 rounded-md cursor-pointer"
                >
                  <p>Add</p>
                </div>
              </div>
            </div>
            <AddedItemsComp
              list={states.socialMediaList}
              removeItem={(item) => {
                const filter = states.socialMediaList.filter(
                  (row) => row.id !== item.id
                );
                setState({ ...states, socialMediaList: filter });
              }}
            />
            <div className="mt-3">
              <p className="text-sm font-bold mb-3">Add your expertise</p>
              <div className="lg:flex">
                <div className="lg:px-1 lg:w-[15%] lg:items-center lg:flex">
                  <SearchDropDownComp
                    options={metaData.expertiseList}
                    onSelected={(item) =>
                      setState({ ...states, expertise: item })
                    }
                  />
                </div>
                <div className="lg:w-[75%] lg:px-2 mt-2 lg:mt-0">
                  <InputFieldComp
                    title={""}
                    defaultValue={states.expDescription}
                    maxLength={200}
                    placeHolder={"one line short descriptionk"}
                    onTextChange={(text) =>
                      setState({ ...states, expDescription: text })
                    }
                  />
                </div>
                <div className="lg:flex lg:w-[5%] lg:justify-center lg:items-center lg:ml-5 mt-2 lg:mt-0">
                  <div
                    onClick={() => addExpertise()}
                    className="bg-green-600 h-[40px] flex items-center text-white text-sm px-7 rounded-md cursor-pointer"
                  >
                    <p>Add</p>
                  </div>
                </div>
              </div>
              <AddedItemsComp
                list={states.expertiseList}
                removeItem={(item) => {
                  const filter = states.expertiseList.filter(
                    (row) => row.id !== item.id
                  );
                  setState({ ...states, expertiseList: filter });
                }}
              />
            </div>
            <div className="mt-3">
              <p className="text-sm font-bold mb-3">
                Select Programming languages
              </p>
              <div className="lg:flex">
                <div className=";g:px-1 lg:w-[90%] lg:items-center lg:flex">
                  <SearchDropDownComp
                    fullWidth={true}
                    options={metaData.codingLanuagesList}
                    onSelected={(item) =>
                      setState({ ...states, language: item })
                    }
                  />
                </div>
                <div className="lg:flex lg:w-[5%] lg:justify-center lg:items-center lg:ml-5 mt-2 lg:mt-0">
                  <div
                    onClick={() => addLanguage()}
                    className="bg-green-600 h-[40px] flex items-center text-white text-sm px-7 rounded-md cursor-pointer"
                  >
                    <p>Add</p>
                  </div>
                </div>
              </div>
              <AddedItemsComp
                list={states.languageList}
                removeItem={(item) => {
                  const filter = states.languageList.filter(
                    (row) => row.id !== item.id
                  );
                  setState({ ...states, languageList: filter });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lg:flex lg:justify-center lg:items-center h-[10%]">
        <div className="lg:flex">
          {step > 1 ? (
            <div
              onClick={() => goBack(step - 1)}
              className="bg-blue-600 text-center lg:mr-5 text-white text-sm py-2 px-7 rounded-full cursor-pointer mt-2 lg:mt-0"
            >
              Back
            </div>
          ) : null}
          <div
            onClick={() => checkFrom()}
            className="bg-blue-600 text-center text-white text-sm py-2 px-7 rounded-full cursor-pointer mt-2 lg:mt-0"
          >
            <p>Save & Go Next</p>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useState } from "react";
import { FaSortDown } from "react-icons/fa";

export default function SearchDropDownComp({
  options = [],
  fullWidth = false,
  onSelected,
}) {
  const [states, setStates] = useState({
    show: false,
    selected: undefined,
  });
  const [filterItems, setFilterItems] = useState(options);

  function filter(text) {
    if (text === "") setFilterItems(options);
    else {
      let filteredData = options.filter((item) =>
        item.label
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(text.toLowerCase().replace(/\s+/g, ""))
      );
      setFilterItems(filteredData);
    }
  }

  return (
    <div className={`w-full relative ${states.show ? "z-10" : "z-0"}`}>
      <div
        onClick={() => {
          if (options.length > 0) setStates({ ...states, show: !states.show });
        }}
        className={`bg-white border border-black cursor-pointer ${
          fullWidth ? "w-full" : "w-full lg:w-[150px]"
        } h-[40px] flex justify-between items-center text-sm px-3 shadow-sm`}
      >
        <p>
          {states.selected ? states.selected?.label.slice(0, 10) : "select "}
        </p>
        <FaSortDown className="text-lg" />
      </div>
      {states.show ? (
        <div
          className={`bg-white text-black text-sm ${
            fullWidth ? "w-[70%]" : "w-[250px]"
          } mt-1 shadow-sm absolute border border-black`}
        >
          <div className="p-3">
            <input
              placeholder="search"
              className="outline-none border border-black h-[40px] px-3 rounded-sm w-full"
              onChange={(e) => filter(e.target.value)}
            />
          </div>
          {filterItems.map((item, _index) => {
            return (
              <div
                onClick={() => {
                  onSelected(item);
                  setStates({ ...states, show: false, selected: item });
                }}
                key={_index}
                className="hover:bg-gray-50 cursor-pointer p-4"
              >
                <p className="">{item.label}</p>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { InputFieldComp } from "../components/InputFieldComp";
import { _apiSearch_url } from "../api";
import { useDebouncedCallback } from "use-debounce";
import DragDropFileComp from "../components/DragDropFileComp";
import {
  _getLocalStorageData,
  checkSpace,
  checkSpecialCharacters,
} from "../helper";
import { toast } from "react-toastify";
import { lsVariables } from "../local";

export const StepOne = ({ user, step, goBack, goNext }) => {
  const [state, setState] = useState({
    user: undefined,
    found: "",
    searchUrl: "",
    fullName: "",
    position: "",
    phoneNum: "",
    file: undefined,
  });

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    const data = _getLocalStorageData(lsVariables.stepOne);
    if (data)
      setState({
        ...state,
        fullName: data?.fullName,
        phoneNum: data?.phoneNum,
        position: data?.position,
        searchUrl: data?.resumeUrl,
        found: "",
      });
  }

  async function serachUrl(value) {
    setState({ ...state, found: "searching..." });
    const params = {
      userId: user.id,
      shareLink: value,
      web: "",
    };
    const id = toast.loading("checking url avaible or not...");
    const found = await _apiSearch_url(params);
    toast.update(id, {
      render: found ? "URL is available" : "URL not available",
      type: "success",
      isLoading: false,
      autoClose: 2000,
    });
    setState({
      ...state,
      found: found ? "(URL is available)" : "(URL not available)",
      searchUrl: value,
    });
  }

  const debounced = useDebouncedCallback((value) => {
    if (checkSpecialCharacters(value) || checkSpace(value)) {
      toast.error("special characters and space is not allowed");
      serachUrl("your-name");
      return;
    }
    if (value) serachUrl(value);
  }, 1000);

  function checkFrom() {
    if (state.file === undefined) {
      toast.error("Please provide profile image");
      return;
    }
    if (state.searchUrl === "") {
      toast.error("Please provide resume link");
      return;
    } else {
      if (
        checkSpecialCharacters(state.searchUrl) ||
        checkSpace(state.searchUrl)
      ) {
        toast.error(
          "special characters and space is not allowed in resume link"
        );
        return;
      }
    }
    if (state.fullName === "") {
      toast.error("Please provide full name");
      return;
    }
    if (state.position === "") {
      toast.error("Please provide position");
      return;
    }
    if (state.phoneNum === "") {
      alert("Please provide phone num");
      return;
    }
    const data = {
      resumeUrl: state.searchUrl,
      fullName: state.fullName,
      position: state.position,
      phoneNum: state.phoneNum,
      profileImg: state.file,
    };
    localStorage.setItem(lsVariables.stepOne, JSON.stringify(data));
    goNext(data);
  }

  return (
    <div className="mt-5 h-[85%] lg:h-[90%]">
      <div className="lg:px-[2%] py-[1%] h-[80%] overflow-auto">
        <>
          <div>
            <div className="">
              <DragDropFileComp
                onFileSelected={(file) => setState({ ...state, file })}
              />
            </div>
            <p
              className={`lg:text-sm ${
                state.file ? "text-green-600" : "text-blue-600"
              } text-xs mt-1`}
            >
              {state.file
                ? "Profile image provided!"
                : "upload profile picture"}
            </p>
          </div>
          <div className="mt-5">
            <InputFieldComp
              title={"Resume Link"}
              defaultValue={state.searchUrl}
              placeHolder={"url name - your name with no special char or space"}
              info={`Examlple: https://codingwithzain.com/online-resume/${state.searchUrl}`}
              onTextChange={(val) => {
                debounced(val);
                setState({ ...state, searchUrl: val });
              }}
              message={state.found}
            />
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-5 mt-5">
            <div>
              <InputFieldComp
                info={""}
                defaultValue={state.fullName}
                title={"Full Name"}
                placeHolder={"full name"}
                onTextChange={(fullName) => setState({ ...state, fullName })}
              />
            </div>
            <div className="mt-5 lg:mt-0">
              <InputFieldComp
                info={""}
                defaultValue={state.position}
                title={"Position"}
                placeHolder={"position"}
                maxLength={100}
                onTextChange={(position) => setState({ ...state, position })}
              />
            </div>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-5 mt-5">
            <div>
              <InputFieldComp
                info={""}
                defaultValue={state.phoneNum}
                title={"Phone Num"}
                inputType="number"
                placeHolder={"phone number with country code"}
                maxLength={20}
                onTextChange={(phoneNum) => setState({ ...state, phoneNum })}
              />
            </div>
            <div></div>
          </div>
        </>
      </div>
      <div className="flex justify-center items-center h-[15%] lg:h-[10%]">
        <div className="flex">
          {step > 1 ? (
            <div
              onClick={() => goBack(step - 1)}
              className="bg-blue-600 mr-5 text-white text-sm py-2 px-7 rounded-full cursor-pointer"
            >
              Back
            </div>
          ) : null}
          <div
            onClick={() => checkFrom()}
            className="bg-blue-600 text-white text-sm py-2 px-7 rounded-full cursor-pointer"
          >
            <p>Save & Go Next</p>
          </div>
        </div>
      </div>
    </div>
  );
};

import axios from "axios";
import { lsVariables } from "../local";
import { _getLoggedInUser } from "../helper";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL;
export const baseURLAssets = process.env.REACT_APP_BASE_URL_ASSETS;

export const axiosClient = axios.create({
  baseURL,
});

export const apiEndPoints = {
  sign_in: "sign_in.php",
  search_url: "search_url.php",
  meta_data: "meta_data.php",
  create_online_resume: "create_online_resume.php",
  upload_file: "upload_file.php",
  get_resume: "get_resume.php",
  get_resume_details: "get_resume_details.php",
};

export function _axiosHeaders() {
  const userJSON = _getLoggedInUser();
  const headers = {
    Authorization: userJSON.webToken,
  };
  return {
    headers: headers,
  };
}

export async function _getMetaData() {
  try {
    let res = await axiosClient.get(apiEndPoints.metaData);
    if (res.status === 200) {
      localStorage.setItem(lsVariables.metaData, JSON.stringify(res.data));
    }
  } catch (e) {
    console.log("axios", e);
  }
}

export async function _apiSignIn(params) {
  try {
    let res = await axiosClient.post(apiEndPoints.sign_in, params);
    if (res.status === 200) {
      localStorage.setItem(lsVariables.user, JSON.stringify(res.data.user));
      toast.success(res.data.message);
      return res.data.user;
    }
  } catch (e) {
    console.log("=> error:_apiSignIn", e);
  }
}

export async function _apiSearch_url(params) {
  let found = false;
  try {
    let res = await axiosClient.post(
      apiEndPoints.search_url,
      params,
      _axiosHeaders()
    );
    if (res.status === 200) {
      if (res?.data?.status === "200") found = true;
    }
  } catch (e) {
    console.log("=> error:_apiSearch_url", e);
  }
  return found;
}

export async function _loadMetaData(params) {
  let metaData = undefined;
  try {
    let res = await axiosClient.post(
      apiEndPoints.meta_data,
      params,
      _axiosHeaders()
    );
    if (res.status === 200) {
      metaData = res?.data;
    }
  } catch (e) {
    console.log("=> error:_apiSearch_url", e);
  }
  return metaData;
}

export async function _apiCreateOnlineResume(params) {
  let dataUploaded = false;
  try {
    let res = await axiosClient.post(
      apiEndPoints.create_online_resume,
      params,
      _axiosHeaders()
    );
    console.log(res.data);
    if (res.status === 200) {
      if (res?.data?.status === "200") dataUploaded = true;
      else {
        toast.error(res.data?.message);
      }
    }
  } catch (e) {
    console.log("=> error: _apiCreateOnlineResume", e);
  }
  return dataUploaded;
}

export async function _apiUploadFile(user, upload_file, fileType) {
  let apiFormData = new FormData();
  apiFormData.append("userId", user.id);
  apiFormData.append("fileType", fileType);
  apiFormData.append("web", "");
  apiFormData.append("file", upload_file);

  let uploaded = false;
  try {
    let res = await axiosClient.post(
      apiEndPoints.upload_file,
      apiFormData,
      _axiosHeaders()
    );
    console.log(res.data);
    if (res.status === 200) {
      if (res?.data?.status === "200") uploaded = true;
      else {
        toast.error(res.data?.message);
      }
    }
  } catch (e) {
    console.log("=> error: _apiUploadFile", e);
  }
  return uploaded;
}

export async function _apiGetResume(params) {
  let apiResponse = undefined;
  try {
    let res = await axiosClient.post(
      apiEndPoints.get_resume,
      params,
      _axiosHeaders()
    );
    if (res.status === 200) {
      if (res?.data?.status === "200") apiResponse = res.data.data;
    }
  } catch (e) {
    console.log("=> error:_loadMetaData", e);
  }
  return apiResponse;
}

export async function _apiGetResumeDetails(name) {
  let apiResponse = undefined;
  try {
    let res = await axiosClient.post(apiEndPoints.get_resume_details, {
      endPoint: name,
    });
    if (res.status === 200) {
      if (res?.data?.status === "200") apiResponse = res.data;
    }
  } catch (e) {
    console.log("=> error:_apiGetResumeDetails", e);
  }
  return apiResponse;
}

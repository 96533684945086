import React from "react";
import { baseURLAssets } from "../api";

export const ResumeLanguages = ({ resume }) => {
  //console.log(resume);
  return (
    <div className="p-[5%] font-Poppins w-[100%] bg-gray-100">
      <p className="font-[700] text-3xl">Programming Languages</p>
      <div className="mt-10 lg:flex lg:flex-wrap">
        {resume?.language.map((item, _index) => {
          return (
            <div
              key={_index}
              className="lg:mr-10 mb-3 hover:border-blue-500 hover:border-[1px] cursor-pointer flex items-center border-[1px] bg-white border-gray-400 px-8 py-5 text-center rounded-md shadow-md"
            >
              <div className="flex justify-center">
                <img
                  className="w-7 h-7"
                  src={`${baseURLAssets}${item?.photo}`}
                  alt="..."
                />
              </div>
              <p className="text-sm font-[300] ml-3">{item?.label}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import React from "react";

export const InputFieldComp = ({
  placeHolder,
  title,
  info,
  extraStyle,
  inputType = "text",
  onTextChange,
  message = "",
  maxLength = 50,
  defaultValue = "",
}) => {
  return (
    <div className={`w-full ${extraStyle}`}>
      {title !== "" ? (
        <div className="flex">
          <p className="text-sm font-semibold">{title}</p>
          {message !== "" ? (
            <p
              className={`text-sm ${
                message === "(URL is available)"
                  ? "text-green-600"
                  : "text-red-600"
              } ml-3`}
            >
              {message}
            </p>
          ) : null}
        </div>
      ) : null}
      <input
        className="w-full text-sm mt-1 outline-none border-[1px] border-gray-400 h-[40px] px-3 rounded-md"
        type={inputType}
        placeholder={placeHolder}
        value={defaultValue}
        maxLength={maxLength}
        onChange={(e) => {
          onTextChange(e.target.value);
        }}
      />
      <p className="text-xs lg:text-sm text-green-600 mt-1">{info}</p>
    </div>
  );
};

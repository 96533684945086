import React from "react";
import { baseURLAssets } from "../api";

export const ResumeSkills = ({ resume }) => {
  return (
    <div className="font-Poppins w-[100%]">
      <p className="font-[700] text-3xl">Expertise</p>
      <div className="mt-10 lg:flex justify-between">
        {resume?.expertise.map((item, _index) => {
          return (
            <div
              key={_index}
              className="mb-5 lg:mr-10 border-[1px] border-gray-400 px-5 py-10 text-center rounded-md shadow-md"
            >
              <div className="flex justify-center">
                <img
                  className="w-10 h-10"
                  src={`${baseURLAssets}${item?.photo}`}
                  alt="..."
                />
              </div>
              <p className="mt-3 text-2xl font-[500]">{item?.label}</p>
              <p className="mt-1 text-sm text-gray-500">{item?.shortDesc}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

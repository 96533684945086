import React from "react";
export const ExperienceComp = ({ list, removeItem }) => {
  return (
    <>
      {list.length > 0 ? (
        <div className="mt-3 bg-gray-100 p-5 rounded-md border-gray-400 border-[2px]">
          {list.map((item, _index) => {
            return (
              <div
                key={_index}
                className="border border-black rounded-md px-4 py-2 items-center mr-3 mb-2"
              >
                <div className="flex items-center justify-between">
                  <p className="text-sm">{`${item?.company} | ${item?.title}`}</p>
                  <div
                    onClick={() => removeItem(item)}
                    className="cursor-pointer w-[15px] h-[15px] ml-2 bg-red-500 rounded-full text-center flex items-center justify-center"
                  >
                    <p className="text-sm text-white">x</p>
                  </div>
                </div>
                <div>
                  <p className="text-sm text-green-600">{`Start Date: ${
                    item?.startDate
                  } | ${
                    item?.present ? "present" : "End Date: " + item?.endDate
                  } `}</p>
                </div>
                <p className="text-sm text-gray-500 mt-2">{item?.desc}</p>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

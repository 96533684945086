import { lsVariables } from "../local";

export function _getLoggedInUser() {
  let userJson = JSON.parse(localStorage.getItem(lsVariables.user));
  if (userJson) {
    return userJson;
  } else {
    return null;
  }
}

export function _getLocalStorageData(key) {
  let data = JSON.parse(localStorage.getItem(key));
  if (data) {
    return data;
  } else {
    return null;
  }
}

export function _deleteLocalStorageData(key) {
  localStorage.removeItem(key);
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const checkSpecialCharacters = (value) => {
  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return format.test(value);
};

export const checkSpace = (value) => {
  return /\s/g.test(value);
};

import React from "react";

export const TextBoxComp = ({
  placeHolder,
  title,
  info,
  extraStyle,
  inputType = "text",
  onTextChange,
}) => {
  return (
    <div className={`w-full ${extraStyle}`}>
      {title !== "" ? <p className="text-sm font-semibold">{title}</p> : null}
      <textarea
        className="w-full text-sm mt-1 outline-none border-[1px] border-gray-400 h-[100px] p-3 rounded-md"
        type={inputType}
        placeholder={placeHolder}
        onChange={(e) => onTextChange(e.target.value)}
      />
      <p className="text-sm text-green-600 mt-1">{info}</p>
    </div>
  );
};

import React from "react";
import { projectsList } from "../local";

export const ResumeProjects = ({ ref, resume }) => {
  return (
    <div ref={ref} className="p-[5%] font-Poppins w-[100%] bg-gray-100">
      <p className="font-[700] text-3xl">Projects</p>
      <div className="mt-10">
        {resume?.projects.map((item, _index) => {
          return (
            <div
              key={_index}
              className="lg:mr-10 mb-3 hover:border-blue-500 hover:border-[1px] cursor-pointer items-center border-[1px] bg-white border-gray-400 px-8 py-5 rounded-md shadow-md"
            >
              {/* <div className="">
                <img className="w-7 h-7" src={item?.image} alt="..." />
              </div> */}
              <div className="lg:flex lg:items-center">
                <p className="text-lg">{item?.title}</p>
                {item?.gitHub === "" ? null : (
                  <a
                    className="text-sm underline text-blue-600 lg:ml-5"
                    target="_blank"
                    rel="noreferrer"
                    href={item?.gitHub}
                  >
                    GitHub Repo
                  </a>
                )}
                {item?.onlineDemo === "" ? null : (
                  <a
                    className="text-sm underline text-blue-600 ml-5"
                    target="_blank"
                    rel="noreferrer"
                    href={item?.onlineDemo}
                  >
                    Online Demo
                  </a>
                )}
              </div>
              <p className="mt-3 lg:mt-1 text-sm lg:text-md font-[300] text-blue-600 italic">
                {item?.startDate} to {item?.endDate}
              </p>
              <p className="text-sm mt-3">{item?.projDesc}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { InputFieldComp } from "../components/InputFieldComp";
import { TextBoxComp } from "../components/TextBoxComp";
import { CheckBoxComp } from "../components/CheckBoxComp";
import { ExperienceComp } from "../components/ExperienceComp";
import { toast } from "react-toastify";
import { lsVariables } from "../local";
import { _getLocalStorageData } from "../helper";

export const ThirdStep = ({ step, goBack, goNext }) => {
  const [states, setStates] = useState({
    conpanyName: "",
    jobTitle: "",
    jobDesc: "",
    startDate: "",
    endDate: "",
    present: false,
    experinceList: [],
  });

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    const data = _getLocalStorageData(lsVariables.stepThree);
    if (data)
      setStates({
        ...states,
        experinceList: data,
      });
  }

  function checkFrom() {
    if (states.experinceList.length === 0) {
      toast.error(`Please provide your job experience`);
      return;
    }
    localStorage.setItem(
      lsVariables.stepThree,
      JSON.stringify(states.experinceList)
    );
    goNext(states.experinceList);
  }
  function addExperience() {
    if (states.conpanyName === "") {
      toast.error(`Please provide company name`);
      return;
    }
    if (states.jobTitle === "") {
      toast.error(`Please provide job title`);
      return;
    }
    if (states.jobDesc === "") {
      toast.error(`Please provide job description`);
      return;
    }
    if (states.startDate === "") {
      toast.error(`Please provide start data`);
      return;
    }
    if (!states.present) {
      if (states.endDate === "") {
        toast.error(`Please provide end date`);
        return;
      }
    }
    const exp = {
      id: states.experinceList.length,
      company: states.conpanyName,
      title: states.jobTitle,
      desc: states.jobDesc,
      startDate: states.startDate,
      endDate: states.endDate,
      present: states.present,
    };
    const list = states.experinceList;
    list.push(exp);
    setStates({ ...states, experinceList: list });
  }
  return (
    <div className="mt-5 h-[90%] overflow-auto">
      <div className="px-[2%] py-[1%] h-[80%] overflow-auto">
        <>
          <div className="mt-1">
            <p className="text-sm font-bold mb-3">
              Tell us about your job experiences
            </p>
            <div className="lg:grid lg:grid-cols-2 lg:gap-5">
              <div>
                <InputFieldComp
                  title={"Company Name"}
                  defaultValue={states.conpanyName}
                  placeHolder={"company name"}
                  maxLength={150}
                  onTextChange={(value) =>
                    setStates({ ...states, conpanyName: value })
                  }
                />
              </div>
              <div className="mt-5 lg:mt-0">
                <InputFieldComp
                  title={"Job Title"}
                  defaultValue={states.jobTitle}
                  placeHolder={"full stack developer / graphic designer etc"}
                  maxLength={150}
                  onTextChange={(value) =>
                    setStates({ ...states, jobTitle: value })
                  }
                />
              </div>
            </div>
            <div className="mt-5 lg:mt-3">
              <TextBoxComp
                title={"Job Description"}
                defaultValue={states.jobDesc}
                placeHolder={"job description"}
                onTextChange={(value) =>
                  setStates({ ...states, jobDesc: value })
                }
              />
            </div>
            <div className="lg:grid lg:grid-cols-4 lg:gap-5 mt-3">
              <div>
                <InputFieldComp
                  title={"Start Date"}
                  defaultValue={states.startDate}
                  placeHolder={"end date"}
                  info={""}
                  inputType="date"
                  onTextChange={(value) =>
                    setStates({ ...states, startDate: value })
                  }
                />
              </div>
              {states.present ? null : (
                <div className="mt-5 lg:mt-0">
                  <InputFieldComp
                    title={"End Date"}
                    defaultValue={states.endDate}
                    placeHolder={"start date"}
                    info={""}
                    inputType="date"
                    onTextChange={(value) =>
                      setStates({ ...states, endDate: value })
                    }
                  />
                </div>
              )}
              <div className="py-5 lg:py-0">
                <CheckBoxComp
                  label={"Present"}
                  onCheck={(value) =>
                    setStates({
                      ...states,
                      present: value,
                      endDate: value ? "" : states.endDate,
                    })
                  }
                />
              </div>
              <div className="h-full flex items-end">
                <div
                  onClick={() => addExperience()}
                  className="bg-green-600 w-full h-[40px] flex items-center text-white text-sm px-7 rounded-md cursor-pointer"
                >
                  <p className="text-center w-full">Add</p>
                </div>
              </div>
            </div>
            <div>
              <ExperienceComp
                list={states.experinceList}
                removeItem={(item) => {
                  const filter = states.experinceList.filter(
                    (row) => row.id !== item.id
                  );
                  setStates({ ...states, experinceList: filter });
                }}
              />
            </div>
          </div>
        </>
      </div>
      <div className="lg:flex justify-center items-center h-[10%]">
        <div className="lg:flex text-center">
          {step > 1 ? (
            <div
              onClick={() => goBack(step - 1)}
              className="bg-blue-600 lg:mr-5 text-white text-sm py-2 px-7 rounded-full cursor-pointer mt-3 lg:mt-0"
            >
              Back
            </div>
          ) : null}
          <div
            onClick={() => checkFrom()}
            className="bg-blue-600 text-white text-sm py-2 px-7 rounded-full cursor-pointer mt-3 lg:mt-0"
          >
            <p>Save & Go Next</p>
          </div>
        </div>
      </div>
    </div>
  );
};

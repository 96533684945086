export const initReduxData = {
  user: null,
  show: false,
  heading: "Loading...",
  detail: "",
  resume: null,
};

export const lsVariables = {
  user: "current_user",
  metaData: "meta_data",
  stepOne: "step_one",
  stepTwo: "step_two",
  stepThree: "step_three",
  stepFour: "step_four",
};

export const socialMediaNames = [
  { label: "LinkedIn" },
  { label: "GitHub" },
  { label: "Behance" },
  { label: "Youtube" },
];

export const navOptions = [
  "Home",
  "Expertise",
  "Languages",
  "Experience",
  "Projects",
];

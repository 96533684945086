import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAdeMeGSwuXB8yFdUwaQfbKCYpMh69JrCM",
  authDomain: "codingwithzain-19704.firebaseapp.com",
  projectId: "codingwithzain-19704",
  storageBucket: "codingwithzain-19704.appspot.com",
  messagingSenderId: "948313171930",
  appId: "1:948313171930:web:eeee868a671f94857642e3",
  measurementId: "G-W30NFFK1VV",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };

import React, { useEffect, useState } from "react";
import DragDropFileComp from "../components/DragDropFileComp";
import { toast } from "react-toastify";
import { InputFieldComp } from "../components/InputFieldComp";
import { TextBoxComp } from "../components/TextBoxComp";
import SearchDropDownComp from "../components/SearchDropDownComp";
import { ProjectComp } from "../components/ProjectComp";
import { lsVariables } from "../local";
import { _getLocalStorageData } from "../helper";

export const FourthStep = ({ step, goBack, goNext, metaData }) => {
  const [state, setState] = useState({
    file: undefined,
    title: "",
    projectType: undefined,
    startDate: "",
    endDate: "",
    projDesc: "",
    gitHub: "",
    onlineDemo: "",
    projectsList: [],
  });

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    const data = _getLocalStorageData(lsVariables.stepFour);
    if (data)
      setState({
        ...state,
        projectsList: data?.projectsList,
      });
  }

  function checkFrom() {
    if (state.file === undefined) {
      toast.error(`Please upload your resume`);
      return;
    }
    const data = {
      resumePdf: state.file,
      projectsList: state.projectsList,
    };
    localStorage.setItem(lsVariables.stepFour, JSON.stringify(data));
    goNext(data);
  }

  function addProject() {
    if (state.title === "") {
      toast.error("Please provide project title");
      return;
    }
    if (state.projectType === undefined) {
      toast.error("Please provide project type");
      return;
    }
    if (state.startDate === "") {
      toast.error("Please provide project start date");
      return;
    }
    if (state.endDate === "") {
      toast.error("Please provide project end date");
      return;
    }
    if (state.projDesc === "") {
      toast.error("Please provide project description");
      return;
    }
    const proj = {
      id: state.projectsList.length,
      title: state.title,
      projType: state.projectType.id,
      startDate: state.startDate,
      endDate: state.endDate,
      gitHub: state.gitHub,
      demoLink: state.onlineDemo,
      desc: state.projDesc,
    };
    const list = state.projectsList;
    list.push(proj);
    setState({ ...state, projectsList: list });
  }

  return (
    <div className="mt-5 h-[90%]">
      <div className="lg:px-[2%] py-[1%] h-[80%] overflow-auto">
        <>
          <div className="mt-1">
            <p className="text-sm font-bold mb-3">Upload Resume/CV</p>
            <DragDropFileComp
              onFileSelected={(file) => setState({ ...state, file })}
              fileTypes={["pdf"]}
            />
            <p className="text-sm font-bold mb-3 mt-5">Projects you've done</p>
            <div className="lg:grid lg:grid-cols-2 lg:gap-5 mt-3 items-center">
              <div>
                <InputFieldComp
                  info={""}
                  defaultValue={state.title}
                  title={"project title"}
                  placeHolder={"full name"}
                  onTextChange={(title) => setState({ ...state, title })}
                />
              </div>
              <div>
                <p className="text-sm font-bold mt-5 lg:mt-0">
                  select project type
                </p>
                <SearchDropDownComp
                  fullWidth={true}
                  options={metaData.expertiseList}
                  onSelected={(item) =>
                    setState({ ...state, projectType: item })
                  }
                />
              </div>
            </div>
            <div className="lg:grid lg:grid-cols-2 lg:gap-5 mt-3">
              <div>
                <InputFieldComp
                  title={"Start Date"}
                  defaultValue={state.startDate}
                  placeHolder={"end date"}
                  info={""}
                  inputType="date"
                  onTextChange={(value) =>
                    setState({ ...state, startDate: value })
                  }
                />
              </div>
              <div className="mt-5 lg:mt-0">
                <InputFieldComp
                  title={"End Date"}
                  defaultValue={state.endDate}
                  placeHolder={"start date"}
                  info={""}
                  inputType="date"
                  onTextChange={(value) =>
                    setState({ ...state, endDate: value })
                  }
                />
              </div>
            </div>
            <div className="lg:grid lg:grid-cols-2 lg:gap-5 mt-3">
              <div>
                <InputFieldComp
                  info={""}
                  title={"GitHub Link"}
                  defaultValue={state.gitHub}
                  placeHolder={"github repo link"}
                  onTextChange={(value) =>
                    setState({ ...state, gitHub: value })
                  }
                />
              </div>
              <div>
                <InputFieldComp
                  info={""}
                  title={"Online Demo Link"}
                  defaultValue={state.onlineDemo}
                  placeHolder={"online demo link"}
                  onTextChange={(value) =>
                    setState({ ...state, onlineDemo: value })
                  }
                />
              </div>
            </div>
            <div className="mt-3">
              <TextBoxComp
                title={"project Description"}
                defaultValue={state.projDesc}
                placeHolder={"project description"}
                onTextChange={(value) =>
                  setState({ ...state, projDesc: value })
                }
              />
            </div>
            <div
              onClick={() => addProject()}
              className="bg-green-600 w-full h-[40px] flex items-center text-white text-sm px-7 rounded-md cursor-pointer"
            >
              <p className="text-center w-full">Add</p>
            </div>
            <div>
              <ProjectComp
                list={state.projectsList}
                removeItem={(item) => {
                  const filter = state.projectsList.filter(
                    (row) => row.id !== item.id
                  );
                  setState({ ...state, projectsList: filter });
                }}
              />
            </div>
          </div>
        </>
      </div>
      <div className="lg:flex lg:justify-center items-center h-[10%]">
        <div className="lg:flex text-center">
          {step > 1 ? (
            <div
              onClick={() => goBack(step - 1)}
              className="bg-blue-600 lg:mr-5 text-white text-sm py-2 px-7 rounded-full cursor-pointer mt-5 lg:mt-0"
            >
              Back
            </div>
          ) : null}
          <div
            onClick={() => checkFrom()}
            className="bg-blue-600 text-white text-sm py-2 px-7 rounded-full cursor-pointer mt-3 lg:mt-0"
          >
            <p>Submit</p>
          </div>
        </div>
      </div>
    </div>
  );
};

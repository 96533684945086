import React, { useEffect, useState } from "react";
import { navOptions } from "../local";
import { IoMdDownload } from "react-icons/io";
import { baseURLAssets } from "../api";
import { _getLoggedInUser } from "../helper";
import { Link } from "react-router-dom";

export const ResumeHeader = ({ onNavClick, resume, user }) => {
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    if (resume?.resume?.userId == user?.id) {
      setEditMode(true);
    }
  }, []);
  return (
    <div className="w-full h-[100%]">
      {editMode ? (
        <Link
          to={"/update-resume"}
          className="bg-blue-600 rounded-full text-white font font-Poppins absolute right-5 top-5"
        >
          <p className="px-4 py-2 text-sm">Edit Resume</p>
        </Link>
      ) : null}
      <div className="invisible lg:visible lg:flex justify-center py-5">
        <div className="flex">
          {navOptions.map((item, _index) => {
            return (
              <div
                onClick={() => onNavClick(item)}
                key={_index}
                className="hover:border-b-blue-600 hover:border-b-[2px] mr-10 hover:text-blue-600 cursor-pointer"
              >
                <p>{item}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full h-full lg:grid lg:grid-cols-2">
        <div className="flex lg:pr-[5%] justify-center lg:justify-end lg:items-center">
          <div>
            <img
              src={`${baseURLAssets}${resume?.resume?.profileImage}`}
              className="w-[300px] h-[300px] rounded-full"
              alt="..."
            />
            <div className="mt-5 flex justify-center text-md">
              <a
                href={`${baseURLAssets}${resume?.resume?.resumeLink}`}
                target="_blank"
                rel="noreferrer"
                className="flex items-center  rounded-full py-2 bg-blue-600 text-white px-7 "
              >
                <IoMdDownload />
                <p className="text-center ml-3">Resume</p>
              </a>
            </div>
          </div>
        </div>
        <div className="p-[10%]">
          <div className="font-Poppins font-[300] h-[100%] items-center flex">
            <div>
              <p>Hello! My name is</p>
              <p className="text-3xl mt-1 font-Poppins font-[700]">
                {resume?.resume?.fullName}
              </p>
              <p className="mt-2">{resume?.resume?.position}</p>
              <p className="mt-2 text-sm">📞 +{resume?.resume?.phoneNum}</p>
              <div className="flex mt-5">
                {resume?.socialMedia.map((item, _index) => {
                  return (
                    <div key={_index} className="mr-5 cursor-pointer">
                      <a href={item?.smUrl} target="_blank" rel="noreferrer">
                        <img
                          className="w-7 h-7"
                          src={`${baseURLAssets}${item?.photo}`}
                          alt="..."
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { FileUploader } from "react-drag-drop-files";

function DragDropFileComp({ fileTypes = ["jpeg", "png"], onFileSelected }) {
  const handleChange = (file) => {
    onFileSelected(file);
  };
  return (
    <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
  );
}

export default DragDropFileComp;

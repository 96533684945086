import React from "react";
import checck_img from "../assets/images/check.png";

export const ExperienceResume = ({ resume }) => {
  return (
    <div className="p-[5%] font-Poppins w-[100%] bg-white">
      <p className="font-[700] text-3xl">Experince</p>
      <div className="mt-10">
        {resume?.experience.map((item, _index) => {
          return (
            <div className="mt-5" key={_index}>
              <div className="flex">
                <img alt="..." src={checck_img} className="w-5 h-5" />
                <div className="ml-5">
                  <div className="lg:flex items-center">
                    <p className="text-sm lg:text-lg font-[600]">
                      {item?.jobTitle} | {item?.company}
                    </p>
                    <p className="text-sm lg:text-md font-[300] lg:ml-5 text-blue-600 italic">
                      {item?.startDate} |{" "}
                      {item?.present === "Yes" ? "present" : item?.endDate}
                    </p>
                  </div>
                  <div className="mt-2 text-sm lg:text-md text-gray-600">
                    <p>{item?.jobDesc}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

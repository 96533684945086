import React from "react";
import { useSelector } from "react-redux";
import googleImg from "../assets/images/google.png";
import { IoMdClose } from "react-icons/io";

export const LoginModal = ({ login, onClose }) => {
  const appStates = useSelector((state) => state.appStoredData);
  const { showLoginModal } = appStates.allData;

  if (!showLoginModal) return;

  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
              <div className="flex justify-end m-3">
                <IoMdClose className="text-red-600" onClick={() => onClose()} />
              </div>
              <div className="bg-white px-10 pb-4">
                <div className="flex justify-center">
                  <div>
                    <p className="text-sm text-gray-500">
                      Please Login With your Google Account
                    </p>
                    <div
                      onClick={() => login()}
                      className="flex mt-3 bg-white border h-[40px] border-black items-center text-black px-5 rounded-md cursor-pointer hover:shadow-md"
                    >
                      <img
                        src={googleImg}
                        alt="..."
                        className="w-[20px] h-[20px]"
                      />
                      <p className="text-center text-sm ml-5 w-full">
                        Sign in With Google
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    allData: {
      user: null,
      show: false,
      heading: "Loading...",
      detail: "",
      resume: null,
      showLoginModal: false,
    },
  },
  reducers: {
    updateState: (state, action) => {
      state.allData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateState } = counterSlice.actions;
export default counterSlice.reducer;

import React, { useEffect, useState } from "react";
import iconImg from "../assets/images/icon.png";
import googleImg from "../assets/images/google.png";
import { doSignInWithGoogle, doSignOut } from "../firebase/auth";
import { _apiGetResume, _apiSignIn } from "../api";
import { auth } from "../firebase/firebase";
import { Link, useNavigate } from "react-router-dom";
import { _getLoggedInUser } from "../helper";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../redux/reducer";
import { initReduxData } from "../local";
import onlineResumeImg from "../assets/images/online-resume.jpg";
import { LoginModal } from "../modals/LoginModal";

export const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appStates = useSelector((state) => state.appStoredData);
  const { user, resume } = appStates.allData;
  const [state, setState] = useState({
    showLogout: false,
  });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) loadPage();
    });
  }, []);

  async function loadPage() {
    const localUser = _getLoggedInUser();
    if (!localUser) return;
    dispatch(updateState({ ...appStates.allData, show: true }));
    const resume = await _apiGetResume({
      userId: localUser.id,
      web: "",
    });
    dispatch(
      updateState({
        ...appStates.allData,
        show: false,
        user: localUser,
        resume,
      })
    );
  }

  async function googleLogin() {
    dispatch(updateState({ ...appStates.allData, showLoginModal: false }));
    const user = await doSignInWithGoogle();
    if (!user) return;
    const params = {
      fullName: user?.displayName,
      photo: user?.photoURL,
      email: user?.email,
      fcmToken: "dumy-fcm-token-2",
      googleId: user?.uid,
      web: "",
    };
    dispatch(
      updateState({ ...appStates.allData, show: true, showLoginModal: false })
    );
    await _apiSignIn(params);
    const localUser = _getLoggedInUser();
    if (!localUser) return;
    const resume = await _apiGetResume({
      userId: localUser.id,
      web: "",
    });
    dispatch(
      updateState({
        ...appStates.allData,
        show: false,
        user: localUser,
        resume,
        showLoginModal: false,
      })
    );
  }

  async function logOut() {
    localStorage.clear();
    updateState(initReduxData);
    doSignOut();
    window.location.reload();
  }

  const RenderUI = () => {
    if (user) {
      if (resume) {
        return (
          <div className="lg:flex">
            <a
              href="/update-resume"
              rel="nonref"
              className="lg:ml-5 flex bg-blue-600 border h-[50px] items-center text-white px-5 rounded-md cursor-pointer hover:shadow-md"
            >
              <p className="text-center text-sm">Update Resume!</p>
            </a>
            <Link
              to={`/online-resume/${resume.shareLink}`}
              className="lg:ml-5 flex bg-blue-600 border h-[50px] items-center text-white px-5 rounded-md cursor-pointer hover:shadow-md"
            >
              <p className="text-center text-sm">View Online Resume</p>
            </Link>
          </div>
        );
      } else {
        return (
          <a
            href="/create-resume"
            rel="nonref"
            className="ml-5 flex bg-white border h-[50px] border-black items-center text-black px-5 rounded-md cursor-pointer hover:shadow-md"
          >
            <p className="text-center text-sm">
              Create Your Online Resume Now!
            </p>
          </a>
        );
      }
    } else
      return (
        <div
          onClick={() => googleLogin()}
          className="flex bg-white border h-[50px] border-black items-center text-black px-5 rounded-md cursor-pointer hover:shadow-md"
        >
          <img src={googleImg} alt="..." className="w-[30px] h-[30px]" />
          <p className="text-center text-sm ml-5 w-full">Sign in With Google</p>
        </div>
      );
  };
  return (
    <div className="w-screen h-screen font-Poppins">
      <LoginModal
        login={() => googleLogin()}
        onClose={() => {
          dispatch(
            updateState({ ...appStates.allData, showLoginModal: false })
          );
        }}
      />
      <div className="w-[100%] h-[70%] bg-black">
        <div className="py-5 lg:py-0 lg:grid lg:grid-cols-4 h-full">
          <div className="flex justify-center items-center col-span-1">
            <img
              src={iconImg}
              alt="..."
              className="w-[150px] h-[150px] lg:w-[250px] lg:h-[250px]"
            />
          </div>
          <div className="flex justify-center items-center col-span-3">
            {user ? (
              <div className="absolute right-2 top-2">
                <div className="flex justify-end">
                  <img
                    onClick={() =>
                      setState({ ...state, showLogout: !state.showLogout })
                    }
                    alt="..."
                    src={user?.photo}
                    className="w-[40px] h-[40px] cursor-pointer rounded-full"
                  />
                </div>
                {state.showLogout ? (
                  <div className="border-white border-[1px] py-2 rounded-md mt-2 px-2 bg-white text-black">
                    <p className="text-xs p-2">{user?.fullName}</p>
                    <p className="text-xs p-2">{user?.email}</p>
                    <p
                      onClick={() => logOut()}
                      className="text-xs text-red-600 p-2 cursor-pointer hover:underline"
                    >
                      Logout
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="text-white px-5 lg:px-0">
              <p className="text-3xl font-bold">Welcome to Coding With Zain</p>
              <p className="w-[70%] mt-3 text-sm">
                This platform is for those who wants to learn how to code, get a
                job, and get development related guidance!
              </p>
              <div className="flex mt-5">
                <Link
                  to={"online-resume/zainulabideen"}
                  className="bg-white text-black px-5 py-3 cursor-pointer rounded-full"
                >
                  <p className="text-center text-sm">
                    Zain UL Abideen | Resume
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[80%] lg:h-[70%] font-Poppins lg:grid grid-cols-2 gap-5 p-[2%]">
        <div className="">
          <img
            src={onlineResumeImg}
            alt="..."
            className="w-[100%] h-full object-contain rounded-2xl"
          />
        </div>
        <div className="pt-5 lg:pt-0">
          <p className="text-2xl lg:text-3xl font-[200]">
            Create Your Online Resume
          </p>
          <p className="text-sm mt-3 lg:mt-5 text-gray-600">
            Create your professional online resume in just a few steps and
            receive a shareable link that you can send to anyone, anywhere.
            Impress potential employers with an easily accessible, sleek, and
            modern resume!
          </p>
          <div className="flex mt-5">
            <div
              onClick={() => {
                if (user) {
                  if (resume) navigate(`/online-resume/${resume.shareLink}`);
                  else navigate("/create-resume");
                } else {
                  dispatch(
                    updateState({ ...appStates.allData, showLoginModal: true })
                  );
                }
              }}
              className="flex bg-white border h-[50px] border-black items-center text-black px-5 rounded-md cursor-pointer hover:shadow-md"
            >
              <p className="text-center text-sm">
                {resume
                  ? `View Online Resume!`
                  : `Create Your Online Resume Now!`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

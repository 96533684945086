import React from "react";
import { HomePage } from "./home";
import { ResumePage } from "./resume";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CreateResumePage } from "./createResume";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/resume",
    element: <ResumePage />,
  },
  {
    path: "/create-resume",
    element: <CreateResumePage />,
  },
  {
    path: "/update-resume",
    element: <CreateResumePage />,
  },
  {
    path: "/online-resume/:name",
    element: <ResumePage />,
  },
]);

function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;

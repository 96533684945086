import React from "react";

export const CheckBoxComp = ({ label, onCheck }) => {
  return (
    <div className="flex items-center h-full">
      <input type="checkbox" onChange={(e) => onCheck(e.target.checked)} />
      <p className="text-sm font-semibold ml-5">{label}</p>
    </div>
  );
};

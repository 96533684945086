import React from "react";

export const AddedItemsComp = ({ list, removeItem }) => {
  return (
    <>
      {list.length > 0 ? (
        <div className="mt-3 bg-gray-100 p-5 rounded-md flex flex-wrap border-gray-400 border-[2px]">
          {list.map((item, _index) => {
            return (
              <div
                key={_index}
                className="border mb-3 border-black rounded-full px-4 py-2 flex items-center mr-3"
              >
                <p className="text-sm">{item?.label}</p>
                <div
                  onClick={() => removeItem(item)}
                  className="cursor-pointer w-[15px] h-[15px] ml-2 bg-red-500 rounded-full text-center flex items-center justify-center"
                >
                  <p className="text-sm text-white">x</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};
